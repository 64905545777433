import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import SvgInstagramIcon from '../generated/svg/instagram-icon'
import SvgXIcon from '../generated/svg/x-icon'
import SvgBag from '../generated/svg/bag'
import SvgPerson from '../generated/svg/person'
import { pageMaxWidth } from '../styles/page-max-width'
import { pxr } from '../styles/pxr'

const Outer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-block: 5rem;
  color: white;
  background-color: #181F6F;
  @media (max-width: 40rem){
    padding-block: 3rem;
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: grid;
  grid-template-columns: repeat(2, auto);
  @media (max-width: 60rem){
    gap: 1rem;
    grid-template-columns: 1fr auto;
  }
`

const Logo = styled.div`
  grid-area: 1 / 1;
  margin-right: 2rem;
  svg {
    display: block;
    height: 4.375rem;
    width: auto;
    @media (max-width: 60rem){
      height: 2.4375rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
`

const SnsIconContainer = styled.div`
  display: flex;
  gap: 1.875rem;
  grid-area: 3 / 1;
  @media (max-width: 60rem){
    gap: 1.125rem;
    grid-area: 1 / 2;
  }
`

const SnsIconItem = styled.a`
  display: block;
  svg {
    display: block;
    height: 1.625rem;
    width: auto;
    @media (max-width: 60rem){
      padding: 1rem;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
`

const NavContainer = styled.div`
  display: flex;
  gap: ${pxr(38)};
  grid-area: 1 / 2 / span 3 / auto;
  @media (max-width: 60rem){
    grid-area: 2 / 1 / auto / span 2;
    flex-direction: column;
    gap: 0;
  }

`

const NavItem = styled.div`
  @media (max-width: 60rem){
    border-bottom: 1px solid #1A4280;
    padding-block: 1rem;
    
  }
`

const NavHead = styled.div`
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  margin-bottom: 1rem;
  @media (max-width: 60rem){
    font-size: 1.125rem;
  }
`

const NavLink = styled.a`
  font-size: 0.875rem;
  line-height: 2;
  font-weight: 700;
  display: block;
  @media (max-width: 60rem){
    font-size: 0.75rem;
    line-height: 2;
    font-weight: 500;
  }
`

const Copyright = styled.div`
  font-size: 0.875rem;
  grid-area: 2 / 1;
  @media (max-width: 60rem){
    font-size: 0.6875rem;
    grid-area: 3 / 1 / auto / span 2;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Inner>
      <Logo>
        <SvgLogo />
      </Logo>
      <SnsIconContainer>
        <SnsIconItem href="" target="_blank">
          <SvgInstagramIcon />
        </SnsIconItem>
        {/* <SnsIconItem>
          <SvgXIcon />
        </SnsIconItem> */}
      </SnsIconContainer>
      <NavContainer>
        <NavItem>
          <NavHead>
            MEMBER
          </NavHead>
          <NavLink href="/shop/customers/sign_up">新規会員登録</NavLink>
          <NavLink href="/shop/customers/sign_in">ログイン</NavLink>
        </NavItem>
        <NavItem>
          <NavHead>
            PRODUCTS
          </NavHead>
          <NavLink href="/shop/products/BI0001_BC_F_na">BiRyu ダーマチャージセラム</NavLink>
          <NavLink href="/shop/products/BI0002_EC_F_na">BiRyu ダーマチャージスポットクリーム</NavLink>
        </NavItem>
        <NavItem>
          <NavHead>
            ABOUT
          </NavHead>
          <NavLink href="/shop/base_info">当サイトについて</NavLink>
          <NavLink href="/shop/information_categories/news">おしらせ</NavLink>
        </NavItem>
        <NavItem>
          <NavHead>
            SERVICE
          </NavHead>
          <NavLink href="/shop/customer_term">利用規約</NavLink>
          <NavLink href="https://endeavour-co.jp/agreement/">個人に関する情報の取扱いについて</NavLink>
          <NavLink href="/shop/law_info">特定商取引法に基づく表記</NavLink>
          <NavLink href="/shop/contact">お問い合わせ</NavLink>
          <NavLink href="/shop/faq">よくあるご質問</NavLink>
        </NavItem>
      </NavContainer>
      <Copyright>
        ©BiRyu All rights reserved
      </Copyright>
    </Inner>
  </Outer>
}